
const goodsShotUrl = process.env.NODE_ENV === 'production' ? '/public/cps/goods-shot.html' : '/goods-shot.html';
const activityShotUrl = process.env.NODE_ENV === 'production' ? '/public/cps/activity-shot.html' : '/activity-shot.html';

async function snapHtml(url, info) {
    return new Promise((resolve) => {
        const $iframe = document.createElement('iframe');
        $iframe.src = url;
        $iframe.width = '750px';
        $iframe.height = 0;
        $iframe.style.position = 'fixed';
        $iframe.style.zIndex = '-9999';
        $iframe.style.width = '750px';
        document.body.appendChild($iframe);

        window.addEventListener('message', (evt) => {
            if(evt.data && evt.data.action === 'render') {
                resolve(evt.data.imgUrl);
                if(process.env.NODE_ENV === 'production') {
                    if($iframe) {
                        $iframe.parentNode.removeChild($iframe);
                    }
                }
            }
        }, false);

        $iframe.contentWindow.onload = () => {
            $iframe.contentWindow.postMessage({
                action: 'render',
                info
            }, '*');
        };
    });
}

export async function snapGoodsHtml(info) {
    /**
     * info = {
     *     goods: {},
     *     qrcode: 'https://xxx.jpg'
     * }
     * @type {Promise<unknown>}
     */
    const imgUrl = await snapHtml(goodsShotUrl, info);
    return imgUrl;
}

export async function snapActivityHtml(info) {
    /**
     * info = {
     *     pageInfo: {},
     *     qrcode: 'https://xxx.jpg'
     * }
     * @type {Promise<unknown>}
     */
    const imgUrl = await snapHtml(activityShotUrl, info);
    return imgUrl;
}
