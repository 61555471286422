
import '@kaola/qr';

export default function generateQRCode(qrcodeUrl) {
    if(!qrcodeUrl) {
        return '';
    }
    // eslint-disable-next-line
    return QRCode.generatePNG(qrcodeUrl, {
        ecclevel: 'M',
        format: 'html',
        fillcolor: '#FFFFFF',
        textcolor: '#373737',
        margin: 0,
        modulesize: 8
    });
}
