
import Clipboard from 'clipboard';

export default function copy(content, flag) {
    let obj = {
        action: 'copy'
    };
    return new Promise((resolve, reject) => {
        const fakeEl = document.createElement('button');
        
        if(flag) {
            obj = Object.assign(obj, {text: () => content});
        }else {
            obj = Object.assign(obj, {target: () => content});
        }
        const clipboard = new Clipboard(fakeEl, obj);
        
        clipboard.on('success', (e) => {
            clipboard.destroy();
            resolve(e);
        });
        clipboard.on('error', (e) => {
            clipboard.destroy();
            reject(e);
        });
        fakeEl.click();
    });
}
